export default {
    code_baseurl: "https://www.infomart.co.jp/scripts/logon_check.asp",
    oauth2_baseurl: "https://auth.infomart.co.jp/dataServiceNGS/sso/OpenIDConnect/ImPlatformAuth?ssoid=",
    saml_baseurl: "https://auth.infomart.co.jp/dataServiceNGS/sso/saml/sp/login?ssoid=",
    im_baseurl: "https://es.infomart.co.jp/sso/glogin",

    timemanage_url: "https://v-manage.restartz.co.jp/",

    ssotypes: {
        btob: {
            code: "btob",
            name: "BtoBプラットフォーム 受発注",
            types: [
                {
                    value: "none",
                    text: "SSOなし",
                },
                {
                    value: "code",
                    text: "BtoBプラットフォーム 受発注のIDとパスワードでログイン",
                },
                {
                    value: "oauth",
                    text: "OpenIdConnectでのシングル・サインオン",
                },
                {
                    value: "saml",
                    text: "SAMLでのシングル・サインオン",
                },
                {
                    value: "im",
                    text: "特別な方式でのシングル・サインオン",
                },
            ]
        },
    },
}